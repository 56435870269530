.PluginsView {
  .PluginsView__plugin {
    cursor: pointer;
    display: inline-block;
    height: 160px;
    width: 160px;
    border: 3px solid #444;
    margin: 0 12px 12px 0;
    padding: 20px;
    background: #fafafa;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 16px;
    outline: none;

    &.disabled {
      background-color: #ddd;
      opacity: 0.8;
    }
  }

  .PluginsView__plugin .icon {
    height: 39px;
  }

  .PluginsView__plugin .plugin-title-container {
    display: table-cell;
    vertical-align: bottom;
    height: 50px;
    width: 114px;
    .plugin-title-content {
      text-align: center;
    }
  }

  .PluginsView__plugin .actions {
    height: 25px;
  }

  .PluginsView__add-plugin {
    border: 3px dashed #777;
    background: #f4f4f4;
    font-size: 112px;
    line-height: 108px;
    color: #888;
  }

  .PluginSection__header {
    display: flex;
    margin-top: 16px;
  }

  .inline-loading {
    margin: 5px 20px;
    .MuiCircularProgress-root {
      margin-top: -15px;
    }
  }

  .PluginsList {
    margin-bottom: 22px;
  }
}
