.CreateTagsDialog {
  &-container {
    display: flex;
    flex-direction: column;
  }

  &-select {
    margin-top: 10px;
  }
}

.CreateTagsDialogOption {
  display: flex;
  flex-direction: row;

  &-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
  }

  &-color-circle {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 1px solid white;
  }
}
