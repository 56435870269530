.EditTaskTemplateDialog {
  display: flex;
  flex-direction: row;
  min-height: 385px;

  .EditTaskTemplateDialog_column1 {
    margin-right: 8px;
    flex: 2;

    .MuiFormControlLabel-root {
      margin: 6px 0;

      .MuiCheckbox-root {
        padding: 8px;
      }
    }
  }

  .EditTaskTemplateDialog_column2 {
    margin-left: 8px;
    flex: 1;

    .EditTaskTemplateDialog_labor_cost {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      margin-top: 8px;
      margin-bottom: 4px;
    }

    .EditTaskTemplateDialog_total {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      margin-top: 8px;
      margin-bottom: 4px;
    }
  }
}
