.EditUserDialog {
  .MuiDialogContent-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .EditUserDialog__topForm {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .column {
      display: flex;
      flex-direction: column;
    }

    .form-control {
      min-width: 200px;
      margin: 12px;

      &-phone {
        max-width: 130px !important;
        margin-right: 24px !important;
      }
    }

    .right-column {
      &-top-row {
        display: flex;
        align-items: center;
      }

      &-bottom-row {
        margin: 12px;
      }
    }
  }

  &-rooftop-content {
    display: flex;
    flex-direction: row;
    height: 500px;
  }

  .UserOrganizationListView {
    overflow: hidden;
  }

  &-divider {
    flex: 1 0 0;
  }
}
