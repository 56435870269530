.AssignableUsers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
  width: 70%;

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-message {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #000000;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    width: 100%;

    &-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #000000;
    }

    .MuiButton-label {
      color: #FFFFFF;
    }
  }
}
