.IngestProcessListView {
  &__listItem {
    padding: 0;

    &.Table__row:active {
      padding-top: 0;
      font-weight: normal;
    }

    &.selected,
    &.selected:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }

    &__tag {
      cursor: pointer;
      user-select: none;
      width: 100%;
    }

    &.targeted &__tag {
      font-weight: bold;
    }

    &.beginningStep &__tag {
      font-style: italic;
    }

    &__deleteCTA {
      cursor: pointer;
    }
  }

  .CollectionHeaderBar {
    height: 30px;
  }
}
