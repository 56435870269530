.OverviewView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 12px;

  .OverviewView_header {
    display: flex;
    width: 100%;
  }

  .OverviewView_departments {
    display: flex;
    width: 100%;
    margin-top: 12px;
  }
  .Overview_logo {
    .Overview_logoUrl {
      max-width: 300px;
      object-fit: contain;
      margin-top: 12px;
    }
  }

  .Overview_logoForm {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;

    .MuiFormControl-root {
      flex-grow: 0.4;
    }

    button {
      margin-left: 12px;
    }
  }

  .MuiSvgIcon-root {
    cursor: pointer;
    position: relative;
    top: 6px;
    left: -6px;
  }

  .DepartmentView__schedule-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-auto-flow: row;
    place-content: start;
    align-items: center;
    gap: 2px 8px;
    margin-top: 10px;

    label {
      font-weight: 600;
      text-align: right;
      padding-top: 1px;
    }

    .separator {
      grid-column: 1 / -1;
      height: 6px;
    }
  }
}

.EditOrganizationAddressDialog {
  .MuiDialogContent-root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-input {
    padding-bottom: 12px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.EditOrganizationDepartmentDialog {
  &-contact-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-phone {
      margin-right: 16px !important;
    }
  }

  &-schedule {
    display: grid;
    margin: 10px 0 10px 10px;
    grid-template-columns: auto repeat(7, 1fr);
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    place-content: center;
    align-items: center;
    gap: 9px;

    &-day {
      display: flex;
      flex-direction: column;
    }

    &-label {
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
