.TasksView_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .TasksView_labor {
    text-align: end;
  }

  .TasksView_parts {
    text-align: end;
  }

  .TasksView_total {
    text-align: end;
  }

  .TasksView_actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
