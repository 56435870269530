.VendorOrganizations_container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .VendorOrganizations_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-bottom: 1px solid #e6e7e8;
    background: #fbfbfb;
    height: 30px;

    .addIconButton {
      padding: 0px;
    }
  }

  .VendorOrganizations_rooftops {
    height: 100%;
    overflow: auto;

    .VendorOrganizations_rooftop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      height: 30px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);

      &:hover {
        background: #f6f6f6;
      }
    }
  }
}
