.EditTagsDialog {
  &-tags {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 17px;
    justify-content: flex-start;
  }

  &-select-container {
    display: flex;
    justify-content: center;
  }

  &-select {
    margin-top: 15px;
    flex-grow: 1;
  }
}
