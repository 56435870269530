.Table__row {
	align-content: center;
	padding-left: 15px !important;
	padding-right: 10px !important;
	padding-top: 12px;
	justify-content: space-between;
	height: 30px;

	&.Table__row:hover {
		background: #f6f6f6;
		transition: background 0.3;
	}
	
	&.Table__row:active {
		padding-top: 12px;
		font-weight: bold;
	}
}

.Table__selectedRow {
	background: #e9e9e9;
	font-weight: bold;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserOrganizationListView {
  width: 320px;

  .ReactVirtualized__Grid {
    height: 100% !important;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 14px;
  }
}
