.IngestProcessDetailView {
  overflow: auto;

  .IngestProcessDetailView__input_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    display: flex;
    justify-content: center;
  }

  .IngestProcessDetailView__input {
    width: 300px !important;
    margin-bottom: 0 !important;
  }

  .IngestProcessDetailView__select {
    width: 300px !important;
    margin-top: 12px !important;
  }

  .IngestProcessDetailView__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttons-wrapper > :not(:last-child) {
    margin-right: 8px;
  }

  .KeyValueFieldTable {
    max-height: none;
    width: 100%;
    margin: 0;

    &-table-container {
      max-width: none;
    }
  }
}
