.HistoryView {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 4fr;
    align-items: center;
    box-sizing: border-box;
    width: 100%;

    &:hover {
      background: #f6f6f6;
    }
  }

  .cell {
    display: flex !important;
    align-items: center;
    flex: 1 1;
    padding: 0 10px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .header {
    background-color: #fbfbfb;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    &-cell {
      font-weight: 700 !important;
      text-transform: uppercase;
    }
  }

  .list {
    padding-bottom: 30px;
  }
}
