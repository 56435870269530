.Alert {
  &-message {
    display: flex;
    align-items: center;
    max-width: 400px;
  }

  &-icon {
    font-size: 20px;
    fill: #fff !important;

    &-variant {
      margin-right: 8px;
    }
  }

  // Variant styles
  &-primary {
    background-color: #263238 !important;
  }

  &-success {
    background-color: #1AB245 !important;
  }

  &-info {
    background-color: #007CD7 !important;
  }

  &-warning {
    background-color: #FFAB00 !important;
  }

  &-error {
    background-color: #D32F2F !important;
  }
}