@import 'scss/mixins/index';

.UsersList {
  display: flex;
  height: 100%;
  overflow-y: hidden;
  width: 100%;

  .UsersList-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 9px;
    border-bottom: 1px solid #e6e7e8;
    background: #fbfbfb;
    line-height: 30px;
    font-size: 14px;
  }

  .SearchAndActions {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .usersInput {
    margin-right: 11px !important;
    justify-content: center;

    input {
      height: 5px;
    }
  }

  .UsersList-table {
    display: block;
    height: 100%;
  }

  .list {
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
  }

  .table {
    height: 100%;
    width: 100%;
    display: flex !important;
    flex-flow: column;
  }

  .row {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    height: 30px;

    &:hover {
      background: #f6f6f6;
    }
  }

  .cell {
    display: flex !important;
    align-items: center;
    flex: 1 1;
    padding: 0 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .scrollbarAdjustment {
    padding-right: 16px;
  }

  .header {
    background-color: #fbfbfb;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    &-cell {
      font-weight: 700 !important;
      text-transform: uppercase;
    }
  }

  .icon {
    cursor: pointer;
  }

  .actionButtons {
    flex: 0 1;
  }

  .addIconButton {
    padding: 0px !important;
  }

  .addIconButton-bulk {
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 2px;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .loadingSkeleton {
    width: 100%;
    margin: 6px;
  }
}
