.VendorOrganizations_modal_dialog {
  .MuiDialogTitle-root {
    padding: 8px 16px;
  }

  .MuiDialogContent-root {
    padding: 1px 0;
  }

  .VendorOrganizations_modal_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      height: 5px;
      line-height: 5px;
    }
  }

  .MuiPaper-root {
    height: 85%;
    width: 100%;
  }

  .VendorOrganizations_modal_container {
    height: 100%;

    .VendorOrganizations_modal_rooftops {
      height: 100%;

      .MuiListItem-root {
        padding: 0;
        border-bottom: 1px solid rgba(224, 224, 224, 1);

        .MuiTypography-root {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &:hover {
          background: #f6f6f6;
        }
      }
    }
  }
}
