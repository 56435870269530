.KeyValueFieldTable {
  display: flex;
  flex-direction: row;
  max-height: 200px;
  margin-bottom: 15px;
  margin-top: 15px;

  .MuiTableCell-body {
    padding: 0 !important;
    padding-left: 16px !important;
  }

  .MuiTableCell-body.KeyValueFieldTable-delete-cell {
    padding: 0 !important;
  }

  .MuiIconButton-root {
    padding: 0 !important;
  }

  .MuiTableContainer-root {
    border: 1px solid rgba(0, 0, 0, 0.54) !important;
  }

  &-label {
    white-space: nowrap;
    font-size: .9rem !important;
    margin-top: 12px;
    margin-right: 17px;
  }

  &-table-container {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
  }
}
