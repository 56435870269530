$brand-link-color: #d10;
$text-color: #333;

$recon-primary: rgba(198, 0, 0, 1);
$recon-secondary: #37474f;
$recon-tertiary: #9e9e9e;
$recon-grey: #9e9e9e;
$recon-divider-color: #eff0f0;
$recon-page-background: #eaecec;
$recon-background-secondary: #263238;
$recon-background-tertiary: #f0f0f0;
$recon-success: #1ab245;
$recon-error: #d50000;
$recon-error-light: rgba(198, 0, 0, 0.2);
$recon-alt-text: rgba(38, 50, 56, 0.6);
$recon-alt-text-12: rgba(38, 50, 56, 0.12);
$recon-alt-text-38: rgba(38, 50, 56, 0.38);

$recon-disabled: #e0e0e0;
$recon-hover: #eee;
$recon-grey-border: #f2f2f2;
$recon-grey-summary: #f9f9fa;
$recon-dark-grey: #999;
$recon-tab-highlight: #C60000;
$recon-black-shadow: rgba(0,0,0,0.2);
$recon-step-title-color: #868B8E;
$recon-step-title-color: #333333a6;
$recon-dropdown-arrow-color: #B5B9BA;
$recon-icon-color: rgba(38, 50, 56, 0.38);

$vdp-menu-item-color: #fff;
$vdp-selected-menu-item-color: #EFF1F1;

$recon-item-background-color: #f9fafa;
$recon-selected-item-background-color: #fff;

$recon-ok: $recon-success;
$recon-warning: #ffab00;
$recon-danger: $recon-error;

$recon-info: $recon-secondary;
$recon-pause-button: $recon-error;
$recon-complete-button: $recon-success;

$recon-white: white;
$recon-black: black;

$dark-blue:rgb(22, 22, 31);
