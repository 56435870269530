@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';


@import '~bootstrap/scss/utilities/_screenreaders.scss';
@import '~bootstrap/scss/_dropdown.scss';
@import '~bootstrap/scss/_transitions.scss';
@import '~bootstrap/scss/_tooltip.scss';

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: $form-check-inline-margin-x;

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;

    &[type=radio] {
      position: static;
    }
  }
}

.dropdown-item {
  text-decoration: none;
}
