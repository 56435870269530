@import "scss/mixins/layout";
@import "scss/mixins/colors";

span.NotificationTypeSchedule__typeContainer {
  flex: 0 1 230px;
  white-space: normal;
}

.NotificationTypeSchedule__selectContainer {
  flex: 0 1 140px;
  white-space: normal;

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-left: 0;
    padding-right: 0;
  }
}

.MuiSvgIcon-root.NotificationsTableColumnHeader_icon {
  font-size: 20px;
}

.MuiSvgIcon-root.NotificationsSubscriptionSettingIcon {
  margin-right: $large-px;
  box-sizing: content-box;
}

.ShowIndividualButton {
  background-color: transparent;
  color: $recon-primary;
  border: 0;
  padding: 0;
  text-align: left;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
}
