.Admin_Table {
  position: relative;

  &.Admin_Table__selectable .ReactVirtualized__Table__row {
    cursor: pointer;
  }

  .ReactVirtualized__Table__headerRow {
    background: #fbfbfb;
  }

  .ReactVirtualized__Table__row:hover {
    background: #f6f6f6;
    transition: background 0.3;
  }

  .ReactVirtualized__Table__row:active,
  .Admin_Table__selectedRow {
    &, &:hover {
      background: #e9e9e9;
      outline: none;
    }
  }

  .Admin_Table__selectedRow {
    font-weight: bold;
  }

  .Admin_Table__reloadingRow {
    opacity: 0.5;
  }
}
