.IngestProcessDetailView {
  &__list {
    width: 320px;
    border-right: 1px solid #ccc;
  }
}

.IngestProcesses {
  &__list {
    width: 320px;
    border-right: 1px solid #ccc;
  }
}

  .form-group {
    display: flex;
    justify-content: center;
  }
