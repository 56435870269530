@import 'scss/mixins/colors.scss';

.UserTokenView {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .CopyToken {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 0;

    .warning {
      width: 400px;
      height: 50px;
      background: $recon-warning;
      color: $recon-white;
      text-align: center;
      font-weight: bold;

      margin: 10px 0;
    }

    .token-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 400px;

      .token {
        overflow-wrap: break-word;
        max-width: 350px;
      }
    }
  }

  .CreateToken {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px 0;

    .orgSelect {
      min-width: 200px;
    }
  }
}
