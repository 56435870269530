.addTeamDialog-content {
  .UsersList {
    height: calc(100% - 55px);
  }
}

.MuiDialog-paper {
  .MuiDialogContent-root {
    padding-top: 0;
    padding-bottom: 0;
  }
}
