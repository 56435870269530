@import "../../../../../../scss/mixins/colors.scss";

@mixin button-style {
  height: 45px;
  background-color: $dark-blue;
  color: $recon-white;
  padding: 7px 20px;
  border-radius: 5px;
  border: none;
  filter: drop-shadow(2px 2px 5px rgba(22, 22, 31, 0.3));
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25em;
  margin-right: 10px;
}
.templateSelection {
  display: flex;

  .MuiTypography-h6 {
    margin: 15px 0 5px !important;
    font-weight: 600 !important;
  }
}
.filter-section {
  display: flex;
  .filter-selection-wrapper {
    margin: 9px 0;
  }
}
.addCustom-Selection-Container {
  margin: 0 0 0 50px;
  display: inline-flex;
  justify-content: space-evenly;
}
.addStandard-Selection-Container {
  margin: 0 0 0 50px;
  display: inline-flex;
  justify-content: space-evenly;
}
.folioContainer {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.button {
  @include button-style;
}

.button.cancel {
  background-color: $recon-white;
  filter: none;
  color: $dark-blue;
  margin-left: auto;
}

.color-logo-template-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.row1 {
  display: flex;
  padding: 20px 12px !important;
}

.advanced-style-setting-wrapper {
  padding: 0 12px 20px !important;
}

/* Overriding Material UI MuiGrid-root*/
