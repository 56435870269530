.OrganizationDepartmentSubform {
  .OrganizationDepartmentSubform__phone {
    .MuiTextField-root {
      width: 300px;
    }
  }

  .OrganizationDepartmentSubform__email-address {
    .MuiTextField-root {
      width: 300px;
    }
  }

  .OrganizationDepartmentSubform__schedule {
    width: 95%;
  }

  .OrganizationDepartmentSubform__schedule-grid {
    display: grid;
    margin: 10px 0 10px 10px;
    grid-template-columns: auto repeat(7, 1fr);
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    place-content: center;
    align-items: center;
    gap: 9px;

    .form-control {
      width: 100px;
    }

    label {
      font-weight: 600;
    }
  }
}
