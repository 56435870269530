
.PermissionErrorView__container {
	height: 40px;
	background-color: #263238;
	padding-bottom: 18px;
	padding-left: 20px;
	margin-left: 20px;
	margin-right: 20px;
	justify-items: center;
	justify-content: center;
}

.PermissionErrorView__icon {
	margin-right: 15px;
	margin-top: 5px;
	position: absolute;
}

.PermissionErrorView__p {
	color: white;
	align-content: center;
	display: inline-block;
}

.PermissionErrorView__p__message { 
	margin-top: 10px;
	margin-left: 40px;
}
