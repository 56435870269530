.PluginDialog {
  .top-control {
    width: 400px;
  }

  &__text-input {
    margin-left: 100px !important;
    width: 300px !important;
  }

  &__text-area {
    margin-left: 100px !important;
    margin-top: 8px !important;
    width: 400px !important;
    padding: 5px;
  }

  &__switch {
    margin-left: 35px !important;
  }

  &__checkbox_group {
    margin-left: 40px !important;

    &_label {
      margin-right: 12px !important;
    }
  }
}