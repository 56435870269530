.SetUserPasswordDialog {
  z-index: 1500 !important;

  .form-label {
    width: 150px;
  }
  .form-control {
    width: 240px;
    margin-bottom: 12px;
  }

  &-content {
    display: flex;
    flex-direction: column;
  }
}
