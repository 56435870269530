.OrganizationsView {
  display: flex;
}

.OrganizationsSideBar {
  width: 320px;
  border-right: 1px solid #ccc;
}

.OrganizationList {
  margin-top: 0;
  padding-left: 0;
  height: 100%;
  overflow: auto;
  cursor: pointer;

  li {
    list-style-type: none;
    height: 30px;
    padding: 5px 0;
    border-bottom: 1px solid #e8e8e8;

    &.selected {
      background-color: #e9e9e9;
      font-weight: bold;
    }

    span {
      margin-left: 16px;
    }
  }
}