@import "scss/mixins/index";

.bulk-user-dialogue-input {
  margin-top: 10px;
  margin-bottom: 10px;
}

.columnHeader {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 0 9px;
  background-color: #fbfbfb;
  font-weight: 700 !important;
  font-size: 18px;
  text-transform: uppercase;
}

.row {
  width: 100%;
}
