.Dialog {
  &-divider {
    flex: 1 0 0;
  }

  &-actions {
    padding: 16px !important;
  }

  &-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;
  }

  .nav.nav-tabs.compact-height {
    margin-top: 0 !important;
  }
}

.Dialog-no-padding {
  .MuiDialogContent-root {
    padding: 0 !important;
  }
}
