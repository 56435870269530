.OrganizationDetailView {
  display: flex;
  flex: 1;
  flex-direction: column;

  .heading {
    min-height: 70px;
  }

  .icon-hover {
    cursor: pointer;
  }
  .inline > .TagChip {
    display: inline-flex;
    color: white
  }
}
