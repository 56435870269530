.WorkflowStepDetailView {
  overflow: auto;

  .WorkflowStepDetailView__schedule {
    width: 95%;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
  }

  .WorkflowStepDetailView__input_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    display: flex;
    justify-content: center;
  }

  .WorkflowStepDetailView__schedule-grid {
    display: grid;
    margin-top: 10px;
    grid-template-columns: 80px 0.8fr 0.8fr;
    grid-template-rows: auto auto auto;
    grid-auto-flow: row;
    place-content: center;
    align-items: center;
    gap: 9px;

    .form-control {
      width: 145px;
    }

    label {
      font-weight: 600;
    }
  }

  .WorkflowStepDetailView__time {
    width: 95%;
    margin: 8px 0px 5px 0px;
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: center;

    label {
      font-weight: 600;
      width: 100px;
      padding-right: 10px;
    }

    p {
      color: #333;
      align-self: center;
      margin: 0px 5px 0px 0px;
    }

    input {
      max-width: 60px;
      margin-right: 5px;
    }
  }

  .WorkflowStepDetailView__input {
    width: 300px !important;
    margin-bottom: 0 !important;
  }

  .WorkflowStepDetailView__select {
    width: 300px !important;
    margin-top: 12px !important;
  }

  .WorkflowStepDetailView__select-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px !important;
    margin-bottom: 4px;
  }

  .WorkflowStepDetailView__select-label {
    margin-right: 12px;
    font-size: 14px !important;
    white-space: nowrap;
  }

  .WorkflowStepDetailView__category-select {
    margin-left: 36px;
  }

  .WorkflowStepDetailView__parent-step-select{
    margin-left: 20px;
  }

  .WorkflowStepDetailView__next-step-select {
    margin-left: 31px;
  }

  .WorkflowStepDetailView__time-input {
    width: 145px !important;
  }

  .WorkflowStepDetailView__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Admin_Table {
    width: 100%;
    height: 500px;
  }
}
