@import "scss/mixins/index";

.AssignMember-search-input-container {
	display: flex;
	align-items: center;

	input {
		border: 0px solid #fff;
		appearance: none;
	}
	input:focus {
		outline: none;
	}
}

.AssignMember-search-input-container-border {
	display: flex;
	border: 2px solid #ccc;

	&:focus {
		outline: none;
		border: 2px solid $recon-grey;
	}
}