.MediaContainer {
  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #000;

    @media screen and (max-width: 575px) {
      margin-bottom: 8px;
    }
  }

  &-no-media {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: rgba(38, 50, 56, 0.38);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
