@import 'scss/mixins/layout';
@import 'scss/mixins/colors';

.OrganizationDialog {
  .form-label {
    width: auto;
  }

  .OrganizationDialog-text-input {
    width: 300px;
  }

  .OrganizationDialog-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 52px;

    .MuiFormControlLabel-root {
      margin-right: $small-px;
    }

    &-label {
      margin-right: $small-px;
    }
  }

  .OrganizationDialog-select {
    width: 300px;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 13px) scale(1);
    pointer-events: none;
  }

  .OrganizationDialog-parent-select {
    margin-top: 8px;
    margin-bottom: 4px;
  }

  .OrganizationDialog-time-zone-select {
    margin-top: 8px;
    margin-bottom: 4px;
  }
}

.OrganizationDialog-flex-container {
  display: flex;
  flex-direction: column;
}
