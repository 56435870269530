.ReconVelocityPluginDialog {
  .ReconVelocityPluginDialog__closeElement {
    display: flex;
    width: 150px;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    --tab-height: 48px;

    height: 100%;

    &__tabContent {
      height: calc(100% - var(--tab-height));
    }
  }
}
