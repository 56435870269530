.EditGroupDialog {
  .right-column .form-label {
    width: 150px;
  }

  .EditGroupDialog__input {
    width: 300px;
  }

  .EditGroupDialog__sectionHeading {
    font-weight: 700;
    text-transform: uppercase;
  }

  .EditGroupDialog__sectionHeading,
  .EditGroupDialog__listItem {
    padding: 4px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .EditGroupDialog__listItem {
    cursor: pointer;

    &:active,
    &.selected {
      &,
      &:hover {
        background: #e9e9e9;
        outline: none;
      }
    }

    &.selected {
      font-weight: bold;
    }
  }

  .EditGroupDialog__spacing {
    height: 15px;
  }

  .EditGroupDialog__main-container {
    flex-direction: row !important;
  }

  .EditGroupDialog__list {
    width: 300px;
  }
}
