$header-height: 60px;
$header-img-height: 40px;

// Z-indexes
// Overlays (e.g. modal dialogs) == 1000

$header-z-index: 900;

$small-px: 5px;
$medium-px: 10px;
$large-px: 15px;

// Flexbox
.flex-stretch {
  display: flex;
  justify-content: space-between;
}
.align-center {
  display: flex;
  justify-content: center;
}
.valign-center {
  display: flex;
  align-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}

// Margins
.margin-top-sm {
  margin-top: $small-px !important;
}
.margin-right-sm {
  margin-right: $small-px !important;
}
.margin-bottom-sm {
  margin-bottom: $small-px !important;
}
.margin-left-sm {
  margin-left: $small-px !important;
}
.margin-sm {
  margin: $small-px !important;
}
.margin-top-md {
  margin-top: $medium-px !important;
}
.margin-right-md {
  margin-right: $medium-px !important;
}
.margin-bottom-md {
  margin-bottom: $medium-px !important;
}
.margin-left-md {
  margin-left: $medium-px !important;
}
.margin-md {
  margin: $medium-px !important;
}
.margin-top-lg {
  margin-top: $large-px !important;
}
.margin-right-lg {
  margin-right: $large-px !important;
}
.margin-bottom-lg {
  margin-bottom: $large-px !important;
}
.margin-left-lg {
  margin-left: $large-px !important;
}
.margin-lg {
  margin: $large-px !important;
}

// Padding
.padding-top-sm {
  padding-top: $small-px !important;
}
.padding-right-sm {
  padding-right: $small-px !important;
}
.padding-bottom-sm {
  padding-bottom: $small-px !important;
}
.padding-left-sm {
  padding-left: $small-px !important;
}
.padding-sm {
  padding: $small-px !important;
}
.padding-top-md {
  padding-top: $medium-px !important;
}
.padding-right-md {
  padding-right: $medium-px !important;
}
.padding-bottom-md {
  padding-bottom: $medium-px !important;
}
.padding-left-md {
  padding-left: $medium-px !important;
}
.padding-md {
  padding: $medium-px !important;
}
.padding-top-lg {
  padding-top: $large-px !important;
}
.padding-right-lg {
  padding-right: $large-px !important;
}
.padding-bottom-lg {
  padding-bottom: $large-px !important;
}
.padding-left-lg {
  padding-left: $large-px !important;
}
.padding-lg {
  padding: $large-px !important;
}
