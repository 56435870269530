.NotificationWindowView {
    overflow: auto;

    .NotificationWindowView__timezone-note {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      font-style: italic;
    }

    .NotificationWindowView__schedule {
      width: 95%;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
    }
  
    .NotificationWindowView__input_container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  
    .form-group {
      display: flex;
      justify-content: center;
    }
  
    .NotificationWindowView__schedule-grid {
      display: grid;
      margin-top: 10px;
      grid-template-columns: 80px 0.8fr 0.8fr;
      grid-template-rows: auto auto auto;
      grid-auto-flow: column;
      place-content: center;
      align-items: center;
      gap: 9px;
  
      .form-control {
        width: 145px;
      }
  
      label {
        font-weight: 600;
      }
    }

    .NotificationWindowView__time-input {
        width: 145px !important;
    }

    #notification-window-save-button {
      float: right;
      margin-right: 20px;
    }
}