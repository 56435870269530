@import 'scss/mixins/index';

.SettingsSectionLink-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: $recon-background-tertiary;
  color: $text-color;
  border: 3px solid $recon-secondary;
  cursor: pointer;
  text-decoration: none;
  margin: 5px;
}

.SettingsSectionLink-icon-container {
  font-size: 48pt;
  line-height: 30pt;
}

.SettingsSectionLink-label {
  font-weight: 600;
  inline-size: 125px;
  overflow-wrap: normal;
}
