.UserOrganizationsDialog {
  z-index: 1500 !important;

  .MuiPaper-root {
    width: 500px !important;
    height: 540px !important;
  }

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .OrganizationsList {
    padding-top: 8px;
    padding-left: 8px;
    flex: 1 0 auto;

    .ListItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;
      user-select: none;

      .ListItem-Control {
        flex: 1 0 auto;
        margin: 0;
      }
    }

    .LoadingIndicator-FetchingNextPage {
      margin-top: 8px;
      display: flex;
      justify-content: center;
    }
  }
}
