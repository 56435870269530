.WorkflowDetailView {
  &__list {
    width: 320px;
    border-right: 1px solid #ccc;
  }
}

.Workflows {
  &__list {
    width: 320px;
    border-right: 1px solid #ccc;
  }
}
