.SharedSteps {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 50%;
  padding-bottom: 40px;

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #000000;
    }

    .dialog-control {
      color: white;
    }
  }

  .steps-mapping {
    margin-top: 20px;
    width: 100%;
  }
}
