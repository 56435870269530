@import 'scss/mixins/index';

.HistoryHeader {
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid #e6e7e8;
  background: #fbfbfb;
  height: 51px;
  font-size: 14px;

  .HistorySearchInput {
    height: 40px;
    margin-right: 11px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      height: 16px;
      width: 200px;
      background: white;
    }
  }

  .HistoryUserSelect {
    line-height: 20px;
  }
}
