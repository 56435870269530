@import 'scss/mixins/colors';

.ContactForm {
  .ContactForm-text-input {
    width: 300px;
  }

  .ContactForm-flex-container {
    display: flex;
    flex-direction: column;
  }

  .ContactForm-SameAsLocation {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $recon-grey;
    outline: none;
  }

  .ContactForm-select {
    width: 300px;
    margin-top: 8px;
    margin-bottom: 4px;
  }
}
