// TODO:
//@import "../../../../../scss/theme/variables.scss";
$muted: rgba(38, 50, 56, 0.6);

.EngageMediaPlaceholder {
  cursor: pointer;

  &-content {
    width: 210.5px;
    max-width: 210.5px;
    height: 162.9px;
    max-height: 162.9px;
    border: 1px dashed $muted;
    border-radius: 3px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgba(38, 50, 56, 0.38);

    .EngageMediaPlaceholder-icon {
      font-size: 20px !important;
    }

    .EngageMediaPlaceholder-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.15px;
    }
  }
}

.EngageMediaPlaceholder.item {
  max-width: 224px;
}

.EngageMediaPlaceholder-small {
  .EngageMediaPlaceholder-title {
    margin-left: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: rgba(38, 50, 56, 0.38);
  }

  .item-content {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }

  .EngageMediaPlaceholder-content {
    width: 80px;
    max-width: 80px;
    height: 80px;
    max-height: 80px;
    object-fit: cover;
    vertical-align: bottom;
    border-radius: 3px;
  }
}
