.UserNotifications__section {
  flex: 1;

  .ReactVirtualized__Table__headerColumn:not(:first-of-type),
  .ReactVirtualized__Table__rowColumn:not(:first-of-type) {
    margin-right: 0;
  }

  .NotificationTypeSchedule__selectContainer {
    .form-control {
      width: auto;
    }
  }
}

.UserOrganizationDetailView {
  flex-grow: 1;
  border-left: 1px solid #ccc !important;
}
