@import '../../../../../scss/mixins/colors.scss';

.badge-image-preview {
  max-width: 300px;
}

.contentArea {
  margin:15px 0;
}

.engage-plugin-dialog-container {
  height: 718px;
}

.save-changes-dialog-header-text {
  padding: 15px;
}

.yes-no-button-dialog-container {
  padding-top: 20px;
  float: right;
}

.no-button {
  height: 45px;
  background-color:$recon-white;
  color:$dark-blue;
  padding:7px 20px;
  border-radius:5px;
  border:none;
  text-transform: uppercase;
  font-weight:bold;
  font-size: 1.25em;
  margin-right:10px;
}