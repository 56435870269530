@import 'scss/mixins/index';

.teams-container {
  display: flex;
  flex-direction: row;
  height: calc(100% - 70px - 48px);

  .teams-side-bar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 250px;
    border-right: 1px solid #e8e8e8;

    .teams-list-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      .teams-list-header {
        padding-left: $medium-px;
        padding-right: $small-px;
        line-height: 40px;
        border-bottom: 1px solid #e8e8e8;
        background-color: #fbfbfb;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .teams-list {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .teams-list-item {
          padding-left: $medium-px;
          padding-right: $small-px;
          line-height: 30px;
          border-bottom: 1px solid #e8e8e8;

          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .teams-list-item.selected {
          background-color: #e9e9e9;
          font-weight: bold;
        }
      }
    }
  }

  .teams-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .teams-details-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      line-height: 40px;
      width: 100%;
      padding-left: $medium-px;
      padding-right: $medium-px;
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
