@import './bootstrap.scss';
@import './mixins/colors';

body,
th,
td,
input,
select,
button,
.tooltip {
  font-size: 14px;
  font-family: 'Source Sans Pro', 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
th,
td,
input,
select,
button {
  color: $text-color;
}

#root {
  overflow: hidden;
}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

h2 {
  font-weight: 600;
}

h4 {
  font-size: 15px;
  font-weight: 700;
}

a {
  color: $brand-link-color;
}

.nav.nav-tabs {
  text-align: center;
  border-bottom: 1px solid #ddd;

  &.compact-height {
    margin-top: -44px !important;
  }

  .nav-item {
    display: inline-block;
    margin-bottom: -1px;
  }

  .nav-link {
    display: inline-block;
    border-bottom: 0px unset;
    padding: 12px 16px;
    margin: 0;
    height: 100%;
    color: $text-color;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      border-bottom: 0px unset;
      margin: 0;
    }

    &:active {
      outline: none;
    }

    &.active {
      background: none !important;
      padding-bottom: 6px;
      border-bottom: 5px solid red;
      margin: 0;
    }
  }
}

.tab-content {
  overflow-y: auto;
  flex: 1;
  height: 100%;

  .tab-pane {
    display: none;

    &.show {
      display: block;
    }
  }
}

.standard-button {
  display: inline-flex;
  align-items: center;
  min-width: 80px;
  background: none;
  border: none;
  padding: 6px 12px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  transition: background 0.2s;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &:hover {
    background: #eee;
  }

  &:active {
    outline: none;

    &:hover {
      background: #d8d8d8;
      transition: background 0s;
    }
  }

  .MuiSvgIcon-root {
    position: relative;
    top: -3px;
    left: -2px;
    margin-bottom: -5px;
  }
}

.standard-button {
  border: 1px solid black;
}

.ReactVirtualized__Grid:focus,
.ReactVirtualized__Table:focus {
  outline: none;
}

.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  padding-left: 6px;
  padding-right: 8px;
}

.ReactVirtualized__Table__headerRow {
  border-bottom: 1px solid #ddd;
  padding-top: 5px;
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid #e8e8e8;
  line-height: 24px;

  &:focus {
    outline: none;
  }

  &:active {
    padding-top: 1px;
  }

  .MuiSvgIcon-root {
    position: relative;
  }
}

.MuiSvgIcon-root {
  height: 12px;
  width: 12px;
  padding: 2px 0;
  color: #444;
}

.truncate {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.version {
  padding-top: 6px;
  color: #888;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

p {
  margin: 0 0 14px;
}

textarea {
  font-family: inherit;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.full-height {
  height: 100%;
}

.page-container {
  height: calc(100% - 60px);
}

.full-width {
  width: 100% !important;
}

.flex-rows {
  display: flex !important;
  flex-direction: column;

  > * {
    min-height: 0;
  }

  &.flex-reverse {
    flex-direction: column-reverse;
  }

  > .separator {
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
}

.flex-columns {
  display: flex !important;
  flex-direction: row;

  > * {
    min-width: 0;
  }

  &.flex-reverse {
    flex-direction: row-reverse;
  }

  > .separator {
    height: 100%;
    border-right: 1px solid #ddd;
  }
}

.flex-grow {
  flex: 1 1 0 !important;
}

.flex-align-start {
  align-items: flex-start !important;
}

.flex-align-center {
  align-items: center !important;
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-justify-start {
  justify-content: flex-start !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-space-between {
  justify-content: space-between;
}

$h-padding: 16px;
$v-padding: 14px;

h4,
h5,
h6 {
  margin: 2px 0 0;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.heading {
  padding: 13px 16px 0;

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }
}

.heading + {
  .nav.nav-tabs {
    margin-top: -2px !important;
  }
}

.padding {
  padding: $v-padding $h-padding;

  > .flex-columns > .separator {
    margin: 0 $h-padding;
  }

  > .flex-rows > .separator {
    margin: $v-padding 0;
  }
}

.side-padding {
  padding: 0 $h-padding;
}

.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.margin {
  margin: $h-padding;
}

.side-margin {
  margin: 0 $h-padding;
}

.breadcrumbs {
  margin: 0 -0.4em;

  a {
    padding: 0 0.5em;
  }

  ~ h2 {
    margin-top: 2px;
  }
}

.spacer {
  height: 15px;
  width: 15px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.nowrap {
  white-space: nowrap;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.center-content {
  position: relative;

  > *:first-child {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.h-center-content {
  position: relative;

  > *:first-child {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.scrolling {
  overflow-y: auto;
}

.h-scrolling {
  overflow-x: auto;
}

.border {
  border: 1px solid #ccc !important;
}

.border-top {
  border-top: 1px solid #ccc !important;
}

.border-bottom {
  border-bottom: 1px solid #ccc !important;
}

.border-left {
  border-left: 1px solid #ccc !important;
}

.border-right {
  border-right: 1px solid #ccc !important;
}

.margin-top {
  margin-top: 15px !important;
}

.margin-bottom {
  margin-bottom: 15px !important;
}

.margin-left {
  margin-left: 15px !important;
}

.margin-right {
  margin-right: 15px !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.negative-side-margins {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.is-invalid > label {
  color: #d10;
}

.form-section {
  margin: 10px 0;
}

.form-group {
  display: flex;
  padding: 3px 0;
}

.form-label {
  display: inline-block;
  width: 100px;
  padding-right: 8px;
  padding-top: 4px;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
}

.form-control {
  width: 300px;
  border: 1px solid #bbb;
  border-radius: 2px;
  padding: 4px;
  line-height: 16px;

  &.is-invalid {
    border: 1px solid #d10;
    border-radius: 3px;
    color: #d10;
  }
}

.form-check {
  input {
    position: relative;
    top: 2px;
    width: auto;
    margin-left: 0;
    margin-right: 6px;
  }
}

.field-wrapper {
  display: inline-block;
  position: relative;
  padding-top: 4px;

  &.boxed {
    padding-top: 0;

    .invalid-feedback {
      position: absolute;
      top: 3px;
      right: 3px;
      width: auto;

      .trigger {
        margin: 0;
      }
    }

    &.select .invalid-feedback {
      top: 5px;
      right: 15px;
    }
  }

  .invalid-feedback {
    position: relative;
    top: 1px;
    display: inline-block;
    width: 0;
    white-space: nowrap;
    overflow: visible;

    .trigger {
      width: 20px;
      font-size: 1.3em;
      line-height: 0.6em;
      color: #d10;
      text-align: center;
      margin: -0.15em 0 -0.15em -0.15em;

      * {
        position: relative;
        top: 1px;
      }
    }
  }
}

.tooltip {
  z-index: 20000;
}
